import { render, staticRenderFns } from "./ContactDetail.vue?vue&type=template&id=f9039dae&scoped=true&"
import script from "./ContactDetail.vue?vue&type=script&lang=js&"
export * from "./ContactDetail.vue?vue&type=script&lang=js&"
import style0 from "./ContactDetail.vue?vue&type=style&index=0&id=f9039dae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9039dae",
  null
  
)

export default component.exports